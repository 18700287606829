import { createRouter, createWebHashHistory, RouteRecordRaw } from "vue-router";
import MapView from "../views/MapView.vue";
import StoppersView from "../views/StoppersView.vue";
import LoginView from "../views/LoginView.vue";
import { getCurrentUser } from "../services/backendService";

const routes: Array<RouteRecordRaw> = [
    {
        path: "/map",
        name: "map",
        component: MapView,
    },
    {
        path: "/stoppers",
        name: "stoppers",
        component: StoppersView,
    },
    {
        path: "/",
        name: "login",
        component: LoginView,
    },
];

const router = createRouter({
    history: createWebHashHistory(),
    routes,
    linkActiveClass: "active",
});

router.beforeEach(async (to) => {
    try {
        if (to.path !== "/") {
            await getCurrentUser();
        }
    } catch (e) {
        return { name: "login" };
    }
    return true;
});

export default router;
