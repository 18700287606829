import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import localizedFormat from "dayjs/plugin/localizedFormat";
import "dayjs/locale/nl";
import "./main.scss";
import "bootstrap";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
    faSquareParking,
    faStreetView,
    faCheckCircle,
    faCircleXmark,
    faTrash,
    faHouseUser,
} from "@fortawesome/free-solid-svg-icons";
// import { faCircleXmark } from "@fortawesome/free-regular-svg-icons";
import { createApp } from "vue";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import VueGoogleMaps from "@fawmi/vue-google-maps";
import App from "./App.vue";
import router from "./router";
import { store, key } from "./store";

library.add(faSquareParking, faStreetView, faCheckCircle, faCircleXmark, faTrash, faHouseUser);

dayjs.extend(relativeTime);
dayjs.extend(localizedFormat);
dayjs.locale("nl-NL");

const app = createApp(App);
app.use(store, key);
app.use(router);
app.component("font-awesome-icon", FontAwesomeIcon);
app.use(VueGoogleMaps, {
    load: {
        key: process.env.VUE_APP_GOOGLE_MAP_API_KEY,
        libraries: "places",
    },
}).mount("#app");
