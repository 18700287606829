<template>
    <nav class="navbar navbar-expand-lg navbar-light bg-light">
        <div class="collapse navbar-collapse" id="navbarTogglerDemo03">
            <ul class="navbar-nav mr-auto mt-2 mt-lg-0">
                <li class="nav-item active">
                    <router-link class="nav-link" to="/map">Leads</router-link>
                </li>
                <li class="nav-item">
                    <router-link class="nav-link" to="/stoppers">Stoppers</router-link>
                </li>
            </ul>
        </div>
    </nav>
</template>

<script setup lang="ts"></script>

<style lang="scss" scoped>
.navbar {
    width: 100%;
}
</style>
