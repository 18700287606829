import { ALL_CARRIERS, Carrier } from "@/model/carrier";
import { Filter } from "@/model/filter";
import { ALL_LEAD_REASONS, LeadReason } from "@/model/leadReason";
import { ServicePointJSON } from "@/model/servicePointJSON";
import { InjectionKey } from "vue";
import { createStore, createLogger, Store, useStore as baseUseStore } from "vuex";

const debug = process.env.NODE_ENV !== "production";

export interface StoreState {
    filter: Filter;
    servicePoints: ServicePointJSON[];
    streetViewPoint: { lat: number; lng: number } | null;
}

export const key: InjectionKey<Store<StoreState>> = Symbol.for("");

const initialState = () => ({
    filter: {
        carriers: ALL_CARRIERS,
        reasons: ALL_LEAD_REASONS,
    },
    servicePoints: [],
    streetViewPoint: null,
});

// getters
const getters = {
    filters: (state: StoreState) => state.filter,
    noFilterSelected: (state: StoreState) =>
        state.filter.reasons.length === 0 && state.filter.carriers.length === 0,
    allFilterSelected: (state: StoreState) =>
        ALL_CARRIERS.every((c) => state.filter.carriers.includes(c)) &&
        ALL_LEAD_REASONS.every((r) => state.filter.reasons.includes(r)),
    streetViewPoint: (state: StoreState) => state.streetViewPoint,
};

const toggleFromArray = <T>(array: T[], value: T): T[] => {
    if (!array) {
        return [value];
    }
    if (array.includes(value)) {
        return array.filter((a) => a !== value);
    }
    return [...array, value];
};

// mutations
const mutations = {
    toggleFilterReason(state: StoreState, filterKey: LeadReason) {
        state.filter = {
            carriers: state.filter.carriers,
            reasons: toggleFromArray<LeadReason>(state.filter.reasons, filterKey),
        };
    },
    toggleFilterCarrier(state: StoreState, filterKey: Carrier) {
        state.filter = {
            reasons: state.filter.reasons,
            carriers: toggleFromArray<Carrier>(state.filter.carriers, filterKey),
        };
    },
    setFilterCarrier(state: StoreState, carrier: Carrier) {
        state.filter = {
            reasons: state.filter.reasons,
            carriers: [carrier],
        };
    },
    clearstreetViewPoint(state: StoreState) {
        state.streetViewPoint = null;
    },
    setStreetViewPoint(state: StoreState, point: { lat: number; lng: number }) {
        state.streetViewPoint = point;
    },
    toggleFilter(state: StoreState, filterKey: LeadReason | Carrier) {
        let newFilter;
        if (filterKey in LeadReason) {
            newFilter = {
                carriers: state.filter.carriers,
                reasons: toggleFromArray<LeadReason>(state.filter.reasons, <LeadReason>filterKey),
            };
        } else if (filterKey in Carrier) {
            newFilter = {
                carriers: toggleFromArray<Carrier>(state.filter.carriers, <Carrier>filterKey),
                reasons: state.filter.reasons,
            };
        } else {
            throw new Error(`UNKNOWN FILTER VALUE: ${filterKey}`);
        }
        state.filter = newFilter;
    },
    clearFilter(state: StoreState) {
        state.filter = {
            carriers: [],
            reasons: [],
        };
    },
    selectAllFilter(state: StoreState) {
        state.filter = {
            carriers: ALL_CARRIERS,
            reasons: ALL_LEAD_REASONS,
        };
    },
};

export const store = createStore<StoreState>({
    state: initialState,
    mutations,
    getters,
    strict: debug,
    plugins: debug ? [createLogger()] : [],
});

// define your own `useStore` composition function
export function useStore() {
    return baseUseStore(key);
}
