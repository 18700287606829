import axios from "axios";
import { flatten, groupBy, partition } from "lodash-es";
import { CARRIERS } from "@/constants/externals";
import { ServicePointJSON } from "@/model/servicePointJSON";
import { ServicePoint } from "@/model/servicePoint";
import { LeadJSON } from "@/model/leadJSON";
import { Carrier, carrierToString } from "@/model/carrier";

const API = axios.create({
    baseURL: process.env.VUE_APP_API_BASE_URL,
    headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
    },
    withCredentials: true,
});

export const getServicepointsByCarrier = async (
    lat: number,
    lon: number,
    radius: number,
    carrier: string,
    country: string,
): Promise<ServicePoint[]> => {
    const params = {
        lat,
        lng: lon,
        radius,
        carrier,
        country,
        limit: 100,
    };
    const { data: response } = await API.get("/servicepoints/near", { params });
    return response.map((point: ServicePointJSON) => ServicePoint.fromJSON(point));
};

export const signIn = async (username: string, password: string) => {
    const { data: response } = await API.post("/session/login", { username, password });
    return response;
};

export const getCurrentUser = async () => {
    const { data: response } = await API.get("/session/current", { withCredentials: true });
    return response;
};

export const startLeadSync = async () => {
    try {
        await API.get("/leads/update/start");
        return true;
    } catch (e) {
        if (e === "Error: Request failed with status code 409") {
            return true;
        }
        return false;
    }
};

export const getLeadsProgress = async () => {
    const { data } = await API.get("/leads/update/progress");
    return data;
};

export const startServicepointsSync = async () => {
    try {
        await API.get("/servicepoints/update/start");
        return true;
    } catch (e) {
        if (e === "Error: Request failed with status code 409") {
            return true;
        }
        return false;
    }
};

export const getServicepointsProgress = async () => {
    const { data } = await API.get("/servicepoints/update/progress");
    return data;
};

export const getLeadsByPipeLine = async (lat: number, lon: number): Promise<ServicePoint[]> => {
    const params = {
        lat,
        lng: lon,
        limit: 100,
    };
    const { data } = await API.get("/leads/near", { params });
    return data.map((d: LeadJSON) => ServicePoint.fromLeadsJSON(d));
};

export const getClosed = async (carrier: Carrier): Promise<ServicePoint[]> => {
    const params = {
        limit: 100,
        carrier: carrierToString(carrier),
    };
    const { data } = await API.get("/servicepoints/closed", { params });
    return data.map((d: ServicePointJSON) => ServicePoint.fromJSON(d));
};

export const checkClosed = async (id: string): Promise<void> => {
    await API.post(`/servicepoints/closed/${id}/check`, {});
};

export const getServicePointsAndLeadsFromAllCarriers = async (
    lat: number,
    lon: number,
    radius: number,
    country: string,
): Promise<ServicePoint[]> => {
    const tasks = [
        ...CARRIERS.map((carrier) =>
            getServicepointsByCarrier(lat, lon, radius, carrier, country).catch(() => []),
        ),
        getLeadsByPipeLine(lat, lon).catch(() => []),
    ];

    const all = flatten(await Promise.all(tasks));
    const grouped = Object.values(groupBy(all, (p: ServicePoint) => p.addressId));
    const [doubles, uniques] = partition(grouped, (x) => x.length > 1);

    const unDoubled = doubles.map((set) => {
        const carriers = flatten(set.map((c: ServicePoint) => c.carriers));
        const first = set[0];
        first.carriers = carriers;
        return first;
    });

    const result = unDoubled.concat(flatten(uniques));
    return result.sort((a, b) => {
        if (a.distance === undefined) {
            return 1;
        }
        if (b.distance === undefined) {
            return -1;
        }
        return a.distance - b.distance;
    });
};
