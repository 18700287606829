import pinNeverReached from "../assets/VIATIM-nb-pin.svg";
import pin250M from "../assets/VIATIM-250m-pin.svg";
import pinLimitedVolume from "../assets/ViaTim-LimitedVolume-pin.svg";
import iconNeverReached from "../assets/ViaTimNeverReached.svg";
import icon250M from "../assets/ViaTim250M.svg";
import iconsLimitedVolume from "../assets/ViaTimLimitedVolume.svg";

export enum LeadReason {
    VIATIM_250M,
    VIATIM_LIMITED_VOLUME,
    VIATIM_NEVER_REACHED,
}

export const ALL_LEAD_REASONS = [
    LeadReason.VIATIM_LIMITED_VOLUME,
    LeadReason.VIATIM_250M,
    LeadReason.VIATIM_NEVER_REACHED,
];

export const toLeadReason = (reason: string | undefined): LeadReason | undefined => {
    if (!reason) {
        return undefined;
    }
    switch (reason) {
        case "NL Geen 250":
            return LeadReason.VIATIM_250M;
        case "NL Nooit bereikt":
            return LeadReason.VIATIM_NEVER_REACHED;
        case "NL Pakketvolume valt tegen":
            return LeadReason.VIATIM_LIMITED_VOLUME;
        default:
            throw new Error(`Reason '${reason}' not found`);
    }
};

export const leadReasonToPin = (reason: LeadReason): string | undefined => {
    switch (reason as LeadReason) {
        case LeadReason.VIATIM_250M:
            return pin250M;
        case LeadReason.VIATIM_NEVER_REACHED:
            return pinNeverReached;
        case LeadReason.VIATIM_LIMITED_VOLUME:
            return pinLimitedVolume;
        default:
            throw new Error(`Reason '${reason}' not found`);
    }
};

export const leadReasonToIcon = (reason: LeadReason): string | undefined => {
    switch (reason as LeadReason) {
        case LeadReason.VIATIM_NEVER_REACHED:
            return iconNeverReached;
        case LeadReason.VIATIM_250M:
            return icon250M;
        case LeadReason.VIATIM_LIMITED_VOLUME:
            return iconsLimitedVolume;
        default:
            throw new Error(`Reason '${reason}' not found`);
    }
};
