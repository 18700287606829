import dayjs, { Dayjs } from "dayjs";
import { LeadJSON } from "./leadJSON";
import { ServicePointJSON } from "./servicePointJSON";
import { Filter } from "./filter";
import { LeadReason, leadReasonToPin, toLeadReason } from "./leadReason";
import { Carrier, toCarrier, carrierToPin } from "./carrier";

export type WeekDay =
    | "monday"
    | "tuesday"
    | "wednesday"
    | "thursday"
    | "friday"
    | "saturday"
    | "sunday";

export class ServicePoint {
    constructor(
        public id: string,
        public name: string,
        public type: string,
        public distance: number,
        public streetname: string,
        public housenr: string,
        public housenr_extra: string,
        public postcode: string,
        public city: string,
        public country: string,
        public highlight: boolean,
        public gps: {
            lat: number;
            lon: number;
        },
        public external_id: string,
        public hours: { [key in WeekDay]: string[] },
        public code: string,
        public carriers: Carrier[],
        public created: Dayjs | null,
        public closed: Dayjs | null,
        public reason?: LeadReason,
        public contact?: {
            email: string;
            phone: string;
        },
    ) {}

    static fromJSON(json: ServicePointJSON) {
        return new ServicePoint(
            json.id,
            json.name,
            json.type,
            json.distance,
            json.streetname,
            json.housenr,
            json.housenr_extra,
            json.postcode,
            json.city,
            json.country,
            json.highlight,
            json.gps,
            json.external_id,
            json.hours,
            json.code,
            toCarrier(json.external_id) != null ? [toCarrier(json.external_id)!] : [],
            dayjs(json.created),
            json.closed != null ? dayjs(json.closed) : null,
            toLeadReason(json.reason),
            json.contact,
        );
    }

    static fromLeadsJSON(json: LeadJSON) {
        return new ServicePoint(
            json.dealId,
            json.name,
            "lead",
            json.distance,
            json.street,
            json.houseNumber,
            "",
            json.postcode,
            json.city,
            json.country,
            false,
            {
                lat: json.location.lat,
                lon: json.location.lng,
            },
            "VIATIM",
            {
                monday: [],
                tuesday: [],
                wednesday: [],
                thursday: [],
                friday: [],
                saturday: [],
                sunday: [],
            },
            json.dealId,
            [],
            null,
            null,
            toLeadReason(json.reason),
            {
                email: json.email || "",
                phone: json.phone || "",
            },
        );
    }

    includedInFilter(filter: Filter) {
        if (this.reason != null) {
            return filter.reasons.includes(this.reason);
        }
        return (
            filter.carriers && filter.carriers.some((carrier) => this.carriers.includes(carrier))
        );
    }

    get isLead() {
        return this.reason && this.carriers.includes(Carrier.VIATIM);
    }

    get pin() {
        if (this.reason != null) {
            return leadReasonToPin(this.reason);
        }
        if (this.carriers && this.carriers.length > 0) {
            return carrierToPin(this.carriers[0]);
        }
        throw new Error("no Icon found");
    }

    get addressId() {
        const PRECISION = 1000;
        return `${Math.round(this.gps.lat * PRECISION)}-${Math.round(this.gps.lon * PRECISION)}`;
    }
}
