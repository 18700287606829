import viatimPin from "../assets/VIATIM-pin.svg";
import dhlPin from "../assets/DHL-pin.svg";
import dpdPin from "../assets/DPD-pin.svg";
import upsPin from "../assets/UPS-pin.svg";
import glsPin from "../assets/GLS-pin.svg";
import homerrPin from "../assets/HOMERR-pin.svg";
import unknownPin from "../assets/UNKNOWN-pin.svg";

import viatimIcon from "../assets/VIATIM.svg";
import dhlIcon from "../assets/DHL.svg";
import dpdIcon from "../assets/DPD.svg";
import upsIcon from "../assets/UPS.svg";
import glsIcon from "../assets/GLS.svg";
import homerrIcon from "../assets/HOMERR.svg";
import unknownIcon from "../assets/UNKNOWN.svg";

export enum Carrier {
    DHL,
    VIATIM,
    UPS,
    DPD,
    HOMERR,
    GLS,
}

export const ALL_CARRIERS = [Carrier.DHL, Carrier.VIATIM, Carrier.UPS, Carrier.DPD, Carrier.HOMERR];
export const toCarrier = (carrier: string | undefined): Carrier | undefined => {
    if (!carrier) {
        return undefined;
    }
    switch (carrier.toUpperCase()) {
        case "DHL":
            return Carrier.DHL;
        case "VIATIM":
            return Carrier.VIATIM;
        case "DPD":
            return Carrier.DPD;
        case "UPS":
            return Carrier.UPS;
        case "HOMERR":
            return Carrier.HOMERR;
        case "GLS":
            return Carrier.GLS;
        default:
            throw new Error(`Carrier '${carrier}' not found`);
    }
};

export const carrierToString = (carrier: Carrier) => {
    switch (carrier) {
        case Carrier.DHL:
            return "DHL";
        case Carrier.VIATIM:
            return "VIATIM";
        case Carrier.DPD:
            return "DPD";
        case Carrier.UPS:
            return "UPS";
        case Carrier.HOMERR:
            return "HOMERR";
        case Carrier.GLS:
            return "GLS";
        default:
            throw new Error(`Carrier '${carrier}' not found`);
    }
};

export const carrierToPin = (carrier: Carrier) => {
    switch (carrier) {
        case Carrier.VIATIM:
            return viatimPin;
        case Carrier.UPS:
            return upsPin;
        case Carrier.DPD:
            return dpdPin;
        case Carrier.DHL:
            return dhlPin;
        case Carrier.HOMERR:
            return homerrPin;
        case Carrier.GLS:
            return glsPin;
        default:
            return unknownPin;
    }
};

export const carrierToIcon = (carrier: Carrier | undefined) => {
    switch (carrier) {
        case Carrier.VIATIM:
            return viatimIcon;
        case Carrier.UPS:
            return upsIcon;
        case Carrier.DPD:
            return dpdIcon;
        case Carrier.DHL:
            return dhlIcon;
        case Carrier.HOMERR:
            return homerrIcon;
        case Carrier.GLS:
            return glsIcon;
        default:
            return unknownIcon;
    }
};

export const carrierStringToIcon = (carrierString: string): string => {
    const carrier = toCarrier(carrierString);
    return carrierToIcon(carrier);
};
